.image-overlay {
    position: relative;
}

.image-overlay img {
    display: block;
    width: 100%;
    height: auto;
}

.image-overlay::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
}
